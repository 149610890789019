export const CDN_URL = "https://nsc.pmdcollab.org/spritecollab"
export const DISCORD_APP_URL = 'https://cdn.discordapp.com/attachments/'

export const REQUEST_ITEMS_SIZE = 100

export type CreditInformation = {
    contact: string | undefined,
    discord: string | undefined,
    name: string | undefined
}

export enum AnimationType {
    ANIM = "Anim",
    SHADOW = "Shadow"
}

export interface IPMDCollab {
    AnimData: IAnimData
}

export interface IAnimData {
    ShadowSize: number
    Anims: {
        Anim: IAnim[]
    }
}

export interface IAnim {
    Name: string
    Index: number
    FrameWidth: number
    FrameHeight: number
    Durations: IDuration
    CopyOf: string
}

export interface IDuration{
    Duration: number | number[]
}

export interface ICreditNames{
    Contact: string[],
    Discord: string[],
    Name: string[]
}

export enum RankMethod{
    POKEDEX_NUMBER = 'Index Number',
    LAST_MODIFICATION = 'Last Modification',
    NAME = 'Name',
    PORTRAIT_AUTHOR = 'Portrait Author',
    SPRITE_AUTHOR = 'Sprite Author',
    PORTRAIT_BOUNTY = 'Portrait Bounty',
    SPRITE_BOUNTY = 'Sprite Bounty'
}

export enum Dungeon {
    FOURTH_STATION_PATH = 'FOURTH_STATION_PATH',
    SEVEN_STATION_PATH = 'SEVEN_STATION_PATH',
    BARREN_VALLEY = 'BARREN_VALLEY',
    DARK_ICE_MOUNTAIN_PEAK = 'DARK_ICE_MOUNTAIN_PEAK',
    DARK_WASTELAND = 'DARK_WASTELAND',
    DEEP_BOULDER_QUARRY = 'DEEP_BOULDER_QUARRY',
    LIMESTONE_CAVERN = 'LIMESTONE_CAVERN',
    DEEP_LIMESTONE_CAVERN = 'DEEP_LIMESTONE_CAVERN',
    ICICLE_FOREST = 'ICICLE_FOREST',
    MURKY_FOREST = 'MURKY_FOREST',
    SPACIAL_CLIFFS = 'SPACIAL_CLIFFS',
    TEMPORAL_SPIRE_FUTURE = 'TEMPORAL_SPIRE_FUTURE',
    TEMPORAL_TOWER_FUTURE = 'TEMPORAL_TOWER_FUTURE',
    VAST_ICE_MOUNTAIN_PEAK = 'VAST_ICE_MOUNTAIN_PEAK',
    VAST_ICE_MOUNTAIN = 'VAST_ICE_MOUNTAIN',
    AMP_PLAINS = 'AMP_PLAINS',
    FAR_AMP_PLAINS = 'FAR_AMP_PLAINS',
    FINAL_MAZE_B23F = 'FINAL_MAZE_B23F',
    FOGGY_FOREST = 'FOGGY_FOREST',
    FOREST_PATH = 'FOREST_PATH',
    GOLD_CHAMBER = 'GOLD_CHAMBER',
    HIDDEN_HIGHLAND = 'HIDDEN_HIGHLAND',
    MYSTERY_JUNGLE_01F_15F = 'MYSTERY_JUNGLE_01F_15F',
    MYSTERY_JUNGLE_16F_30F = 'MYSTERY_JUNGLE_16F_30F',
    MYSTIFYING_FOREST = 'MYSTIFYING_FOREST',
    BEACH_CAVE = 'BEACH_CAVE',
    BOTTOMLESS_SEA = 'BOTTOMLESS_SEA',
    BRINE_CAVE = 'BRINE_CAVE',
    CONCEALED_RUINS = 'CONCEALED_RUINS',
    CRAGGY_COAST = 'CRAGGY_COAST',
    CRYSTAL_CAVE_01F_05F = 'CRYSTAL_CAVE_01F_05F',
    CRYSTAL_CAVE_06F_11F = 'CRYSTAL_CAVE_06F_11F',
    CRYSTAL_CROSSING = 'CRYSTAL_CROSSING',
    DARK_CRATER = 'DARK_CRATER',
    DEEP_DARK_CRATER = 'DEEP_DARK_CRATER',
    DARK_HILL_01F_06F = 'DARK_HILL_01F_06F',
    DARK_HILL_07F_15F = 'DARK_HILL_07F_15F',
    DEEP_DUSK_FOREST_01F_06F = 'DEEP_DUSK_FOREST_01F_06F',
    DEEP_DUSK_FOREST_07F_12F = 'DEEP_DUSK_FOREST_07F_12F',
    DEEP_SEALED_RUIN = 'DEEP_SEALED_RUIN',
    DRENCHED_BLUFF = 'DRENCHED_BLUFF',
    DUSK_FOREST_01F_04F = 'DUSK_FOREST_01F_04F',
    DUSK_FOREST_05F_08F = 'DUSK_FOREST_05F_08F',
    NORTHERN_DESERT_01F_07F = 'NORTHERN_DESERT_01F_07F',
    QUICKSAND_CAVE = 'QUICKSAND_CAVE',
    QUICKSAND_PIT = 'QUICKSAND_PIT',
    ROCK_AEGIS_CAVE = 'ROCK_AEGIS_CAVE',
    SURROUNDED_SEA = 'SURROUNDED_SEA',
    TEMPORAL_SPIRE = 'TEMPORAL_SPIRE',
    TEMPORAL_TOWER = 'TEMPORAL_TOWER',
    TEST_DUNGEON = 'TEST_DUNGEON',
    THE_NIGHTMARE = 'THE_NIGHTMARE',
    TINY_MEADOW = 'TINY_MEADOW',
    TREESHROUD_FOREST_01F_08F = 'TREESHROUD_FOREST_01F_08F',
    TREESHROUD_FOREST_09F_21F = 'TREESHROUD_FOREST_09F_21F',
    STEAM_CAVE = 'STEAM_CAVE',
    QUICKSAND_PIT_2 = 'QUICKSAND_PIT_2',
    LOWER_BRINE_CAVE = 'LOWER_BRINE_CAVE',
    TEMPORAL_TOWER_2 = 'TEMPORAL_TOWER_2',
    CRYSTAL_CAVE_2 = 'CRYSTAL_CAVE_2',
    WATERFALL_CAVE = 'WATERFALL_CAVE',
    WORLD_ABYSS = 'WORLD_ABYSS',
    ZERO_ISLE_EAST_15F_25F = 'ZERO_ISLE_EAST_15F_25F',
    ZERO_ISLE_EAST_26F_40F = 'ZERO_ISLE_EAST_26F_40F',
    ZERO_ISLE_SOUTH_04F_08F = 'ZERO_ISLE_SOUTH_04F_08F',
    ZERO_ISLE_SOUTH_01F_03F = 'ZERO_ISLE_SOUTH_01F_03F',
    BURIED_RELIC_1F_20F = 'BURIED_RELIC_1F_20F',
    BURIED_RELIC_21F_50F = 'BURIED_RELIC_21F_50F',
    BURIED_RELIC_51F_99F = 'BURIED_RELIC_51F_99F',
    DARKNIGHT_RELIC = 'DARKNIGHT_RELIC',
    SHIMMER_DESERT = 'SHIMMER_DESERT',
    UNOWN_RELIC = 'UNOWN_RELIC',
    FROSTY_FOREST = 'FROSTY_FOREST',
    GREAT_CANYON = 'GREAT_CANYON',
    HOWLING_FOREST_01F_06F = 'HOWLING_FOREST_01F_06F',
    HOWLING_FOREST_07F_15F = 'HOWLING_FOREST_07F_15F',
    MT_FARAWAY = 'MT_FARAWAY',
    MT_FARAWAY_10F_20F = 'MT_FARAWAY_10F_20F',
    MT_FARAWAY_30F_39F = 'MT_FARAWAY_30F_39F',
    JOYOUS_TOWER = 'JOYOUS_TOWER',
    LAPIS_CAVE = 'LAPIS_CAVE',
    LIGHTNING_FIELD = 'LIGHTNING_FIELD',
    MAGMA_CAVERN_08F_17F = 'MAGMA_CAVERN_08F_17F',
    MAGMA_CAVERN_18F_23F = 'MAGMA_CAVERN_18F_23F',
    METEOR_CAVE = 'METEOR_CAVE',
    MT_BLAZE = 'MT_BLAZE',
    MT_STEEL_01F_05F = 'MT_STEEL_01F_05F',
    MT_STEEL_06F_08F = 'MT_STEEL_06F_08F',
    MT_FREEZE = 'MT_FREEZE',
    MT_THUNDER_PEAK = 'MT_THUNDER_PEAK',
    MT_THUNDER = 'MT_THUNDER',
    MURKY_CAVE = 'MURKY_CAVE',
    NORMAL_MAZE = 'NORMAL_MAZE',
    NORTHERN_RANGE_01F_07F = 'NORTHERN_RANGE_01F_07F',
    NORTHERN_RANGE_08F_16F = 'NORTHERN_RANGE_08F_16F',
    NORTHWIND_FIELD = 'NORTHWIND_FIELD',
    PITFALL_VALLEY = 'PITFALL_VALLEY',
    POISON_MAZE = 'POISON_MAZE',
    PURITY_FOREST_04F_07F = 'PURITY_FOREST_04F_07F',
    PURITY_FOREST_13F_20F = 'PURITY_FOREST_13F_20F',
    PURITY_FOREST_30F_43F = 'PURITY_FOREST_30F_43F',
    PURITY_FOREST_44F_60F = 'PURITY_FOREST_44F_60F',
    PURITY_FOREST_61F_79F = 'PURITY_FOREST_61F_79F',
    PURITY_FOREST_80F_99F = 'PURITY_FOREST_80F_99F',
    RESCUE_TEAM_MAZE = 'RESCUE_TEAM_MAZE',
    ROCK_PATH = 'ROCK_PATH',
    SILENT_CHASM = 'SILENT_CHASM',
    SILVER_TRENCH = 'SILVER_TRENCH',
    SINISTER_WOODS = 'SINISTER_WOODS',
    SKY_TOWER = 'SKY_TOWER',
    SNOW_PATH = 'SNOW_PATH',
    SOLAR_CAVE = 'SOLAR_CAVE',
    SOUTHERN_CAVERN_01F_23F = 'SOUTHERN_CAVERN_01F_23F',
    SOUTHERN_CAVERN_24F_50F = 'SOUTHERN_CAVERN_24F_50F',
    STORMY_SEA_01F_16F = 'STORMY_SEA_01F_16F',
    STORMY_SEA_16F_39F = 'STORMY_SEA_16F_39F',
    THUNDERWAVE_CAVE = 'THUNDERWAVE_CAVE',
    TINY_WOODS = 'TINY_WOODS',
    UPROAR_FOREST = 'UPROAR_FOREST',
    SERENITY_RIVER = 'SERENITY_RIVER',
    WATERFALL_POND = 'WATERFALL_POND',
    WESTERN_CAVE_B01F_B27F = 'WESTERN_CAVE_B01F_B27F',
    WESTERN_CAVE_B28F_B39F = 'WESTERN_CAVE_B28F_B39F',
    WISH_CAVE_01F_13F = 'WISH_CAVE_01F_13F',
    WISH_CAVE_90F_99F = 'WISH_CAVE_90F_99F',
    WYVERN_HILL = 'WYVERN_HILL'
  }