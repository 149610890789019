  import Buttons from "./components/buttons";

export default function About(){
    return (
        <div className="App">
            <Buttons/>
            <div className='nes-container' style={{height:'90vh', backgroundColor:'rgba(255,255,255,0.8)', display:'flex', flexFlow:'column', alignItems:'center', justifyContent:'space-evenly'}}>
                <h1 className="nes-text is-primary">The NotSpriteCollab Repository</h1>
                <p>This is a version of SpriteCollab for non-Pokémon or non-canon Pokémon (or other content not accepted here). The condition of use of those elements differ from SpriteCollab. Term of use for a specific Pokémon can be found on <a href="https://hacknews.pmdcollab.org/page:notspritecollab_credit">this page</a>. If some are missing, ask the author.</p>
            </div>
        </div>
      );
}